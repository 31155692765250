<template>
  <div class="uk-container uk-margin-medium-top">
    <div class="uk-card uk-card-default uk-card-body">
      <h3>Your Affiliate Link</h3>
      <p class="uk-text-danger">{{ user.referral_code }}</p>
    </div>
    <h3>My Earnings: $ {{ balance }}</h3>
    <table class="uk-table uk-table-divider">
      <thead>
        <tr>
          <th>User</th>
          <th>Country</th>
          <th>Amount $</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(line, i) in earnings" :key="`earning-${i}`">
          <td>{{ line.name }}</td>
          <td>{{ line.country }}</td>
          <td>{{ line.amount }}</td>
        </tr>
        <tr v-if="earnings.length == 0">
          <td colspan="3">You have no earnings yet. Continue sharing your affiliate link to start earning with us.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      earnings: [],
      balance: ''
    }
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/affiliates').then(response => {
        this.earnings = response.data.earnings
        this.balance = response.data.balance
      })
    }
  },
  
  computed: {
    user() {
      return this.$store.getters.getAuthUser
    }
  }
}
</script>